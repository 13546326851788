<template>
    <div>
        <ProductFamiliesMain />
    </div>
</template>

<script>
import ProductFamiliesMain from '@/components/ProductFamiliesMain.vue'

export default {
    components:{
        ProductFamiliesMain
    }
}
</script>