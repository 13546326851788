<template>
    <div v-if="pageContent" class="products">   
        
        <div 
            class="products__block" 
            :style="{ 'background-image' : 'url(' + imgFile(product) + ')' }"
            v-for="product in pageContent" :key="product.id"
        >
        <!-- {{'Product_'+ selectedLangCode}} -->
            <router-link :to="{name : 'Productfamily_'+selectedLangCode, params:{family: product.slug}}" class="d-flex justify-content-center align-items-center h-100">
                <h1 class="text-white hero__title text-center">{{product.name}}</h1>
            </router-link>
        </div>
        <!--<div class="your_design">
            <div class="d-flex justify-content-center align-items-center h-100 flex-column">
                <h1 class="text-white hero__title text-center text-capitalize">Your design</h1>
                <router-link :to="{ name: `ContactBrossure_${selectedLangCode}`}">
                    <img src="../assets/icons/circle-arrow.svg" class="img-fluid">
                </router-link>
            </div>
        </div>-->
        <div class="your_design">
            <div class="d-flex justify-content-center align-items-center h-100 flex-column">
                <h3 class="text-white hero__title text-center">{{$store.state.translation.product_family_partner_title}}</h3>
                <p class="py-4">{{$store.state.translation.product_family_partner_subtitle}}</p>
                <router-link :to="{ name: `Partner_${selectedLangCode}`}" class="link">
                    <img src="../assets/icons/long-arrow-right.svg" class="img-fluid">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data(){
        return {
            pageContent: null      
        }
    },     
    methods:{
        async getCurrentPageContent(){
            let _this = this
            const content = await _this.getProducts()
            _this.pageContent = content           
        },
        imgFile (block){
            return block.image ? this.apiUrl + '/' + block.image.path +  block.image.file_name : ""
        },
        getProducts(){
            let _this = this

             return new Promise((resolve) =>{      
                _this.get('earthlings_product_family', {
                        params: {                        
                            where: [
                                ['where', 'status', '1']                        
                            ],   
                            order_by:[
                                ['sort_order','asc']
                            ]                                                 
                        }
                    },
                ).then((resp) => {        
                    resolve(resp.data.earthlings_product_family)
                })

                })
        }
    },
    created(){
        this.getCurrentPageContent()   
    }
}
</script>
